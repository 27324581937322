import axios from "axios";

export const getAll = (params) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/customer-certificates`, {
    params
  });
};

export const create = payload => {
  return axios.post(`${process.env.VUE_APP_API_URL}/customer-certificates`, payload);
};

export const update = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer-certificates/${id}?_method=PUT`,
    payload
  );
};

export const remove = id => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/customer-certificates/${id}`);
};

export const generate = id => {
  return axios.post(`${process.env.VUE_APP_API_URL}/customer-certificates/generate/${id}`);
};

export const getOriginalApprovalDate = params => {
  return axios.get(`${process.env.VUE_APP_API_URL}/customer-certificates/original-approval-date`, {
    params
  });
};
