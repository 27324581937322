import { create, generate, getAll, remove, update } from "@/services/customerCertificateService";

const state = {
  customerCertificates: [],
  loading: false,
  customerCertificatesPage: null,
  customerCertificatesTotal: 0,
  customerCertificatesPagesNumber: 0,
  generating: false,
  error: null
};

const getters = {
  getCustomerCertificates: state => state.customerCertificates,
  getCustomerCertificate: state => id =>
    _.first(_.filter(state.customerCertificates, customercCertificate => customercCertificate.id == id)),
  getCustomerCertificatesPage: state => state.customerCertificatesPage,
  getCustomerCertificatesTotal: state => state.customerCertificatesTotal,
  getCustomerCertificatesPagesNumber: state => state.customerCertificatesPagesNumber,
  getCustomerCertificatesError: state => state.error,
  getCustomerCertificatesLoading: state => state.loading,
  getCustomerCertificatesGenerating: state => state.generating,
};

const mutations = {
  loadCustomerCertificates(state, payload) {
    state.customerCertificates = _.get(payload, "data");
    state.customerCertificatesPage = _.get(payload, "current_page");
    state.customerCertificatesTotal = _.get(payload, "total");
    state.customerCertificatesPagesNumber = _.get(payload, "last_page");
  },
  setCustomerCertificatesError(state, payload) {
    state.error = payload;
  },
  setCustomerCertificatesLoading(state, payload) {
    state.loading = payload;
  },
  setCustomerCertificatesGenerating(state, payload) {
    state.generating = payload;
  }
};

const actions = {
  async loadCustomerCertificates({ commit }, payload) {
    commit("setCustomerCertificatesLoading", true);
    try {
      const { data } = await getAll(payload);
      commit("loadCustomerCertificates", data);
    } catch (error) {
      console.error("setCustomerCertificatesError", error);
      commit("setCustomerCertificatesError", error);
    }
    commit("setCustomerCertificatesLoading", false);
  },
  async createCustomerCertificate({ dispatch }, payload) {
    await create(payload);
    await dispatch("loadCustomerCertificates");
  },
  async updateCustomerCertificate({ dispatch }, { id, payload }) {
    await update(id, payload);
    await dispatch("loadCustomerCertificates");
  },
  async removeCustomerCertificate({ dispatch }, id) {
    await remove(id);
    await dispatch("loadCustomerCertificates");
  },
  setCustomerCertificatesLoading({ commit }, payload) {
    commit("setCustomerCertificatesLoading", payload);
  },
  setCustomerCertificatesGenerating({ commit }, payload) {
    commit("setCustomerCertificatesGenerating", payload);
  },
  setCustomerCertificatesError({ commit }, payload) {
    commit("setCustomerCertificatesError", payload);
  },
  async generateCustomerCertificate({ commit, dispatch }, payload) {
    commit("setCustomerCertificatesGenerating", true)
    try {
      const { data } = await generate(payload);
      const linkSource = `data:application/df;base64,${_.get(data, 'content')}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = _.get(data, 'file_name');
      downloadLink.click();
      dispatch("showSnackbar", `${_.get(data, 'file_name')} generated.`);
    } catch (error) {
      console.error(error);
      dispatch("showSnackbar", `Oops, Something went wrong!`);
    }
    commit("setCustomerCertificatesGenerating", false);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
