import { create, getAll, preview, remove, update } from "@/services/certificateService";

const state = {
  certificates: [],
  loading: false,
  error: null
};

const getters = {
  getCertificates: state => state.certificates,
  getCertificate: state => id =>
    _.first(_.filter(state.certificates, certificate => certificate.id == id)),
  getCertificatesError: state => state.error,
  getCertificatesLoading: state => state.loading
};

const mutations = {
  loadCertificates(state, payload) {
    state.certificates = payload;
  },
  setCertificatesError(state, payload) {
    state.error = payload;
  },
  setCertificatesLoading(state, payload) {
    state.loading = payload;
  }
};

const actions = {
  async loadCertificates({ commit }) {
    try {
      const { data } = await getAll();
      commit("loadCertificates", data);
    } catch (error) {
      console.error("setCertificatesError", error);
      commit("setCertificatesError", error);
    }
  },
  async createCertificate({ dispatch }, payload) {
    await create(payload);
    await dispatch("loadCertificates");
  },
  async updateCertificate({ dispatch }, { id, payload }) {
    await update(id, payload);
    await dispatch("loadCertificates");
  },
  async removeCertificate({ dispatch }, id) {
    await remove(id);
    await dispatch("loadCertificates");
  },
  async previewCertificate({ dispatch }, payload) {
    const { data: previewContent } = await preview(payload);
    await dispatch("loadCertificates");
    return previewContent;
  },
  setCertificatesLoading({ commit }, payload) {
    commit("setCertificatesLoading", payload);
  },
  setCertificatesError({ commit }, payload) {
    commit("setCertificatesError", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
