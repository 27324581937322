import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch("loadCustomerCertificates");
    next();
  } catch (error) {
    console.error('DEBUG: [modelList] error', error);
  }
};
