export const MODEL_ACTION = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete"
};

export const FIELD_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  TEXTAREA: "textarea",
  FILE: "file",
  IMAGE: "image",
  PDF: "pdf",
  CHECKBOX: "checkbox",
  SWITCH: "switch",
  DATETIME: "datetime",
  DATE: "date",
  TIME: "time",
  HTML: "html"
};

export const FILE_FIELD_TYPES = ["file", "image", "pdf"];

export const FILE_ACCEPTS = {
  FILE: "*",
  IMAGE: "image/png,image/jpeg,image/webp,image/gif",
  PDF: "application/pdf"
};

export const FILE_INPUT_ICON = {
  FILE: "mdi-paperclip",
  IMAGE: "mdi-image-outline",
  PDF: "mdi-file-pdf-box"
};

export const RELATION_TYPES = {
  ONE_TO_ONE: "One to one",
  ONE_TO_MANY: "One to many (read only)",
  MANY_TO_MANY: "Many to many",
  MANY_TO_MANY_COMMA: "Many to many (comma separated)",
  ONE_TO_MANY_IMAGES: "One to many (image upload)"
};

export const USER_TYPES = {
  ADMIN: 1
};
