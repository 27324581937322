import _ from "lodash";
import { RELATION_TYPES } from "./constants";

export const getFieldListData = (pages, model, field) => {
  return _.get(
    _.find(_.get(_.first(_.filter(pages, p => p.to === model)), "fields", []), {
      id: field.id
    }),
    "list",
    []
  );
};

export const isToManyRelation = type =>
  type === RELATION_TYPES.MANY_TO_MANY ||
  type === RELATION_TYPES.ONE_TO_MANY ||
  type === RELATION_TYPES.ONE_TO_MANY_IMAGES ||
  type === RELATION_TYPES.MANY_TO_MANY_COMMA;

export const getPageFieldsHeader = (pages, model) => {
  return _.map(
    _.filter(
      _.get(_.first(_.filter(pages, p => p.to === model)), "fields", []),
      field => field.show
    ),
    field => {
      const headers = {
        text: _.get(field, "label"),
        value: _.get(field, "name"),
        type: _.get(field, "type"),
        is_foreign: _.get(field, "is_foreign"),
        relation_type: _.get(field, "relation_type"),
        foreign_table_column: _.get(field, "foreign_table_column")
      };

      if (isToManyRelation(headers.relation_type)) {
        headers.value = _.get(field, "foreign_table");
      }

      if (
        _.get(field, "type") === "select" ||
        _.get(field, "type") === "multi-select"
      ) {
        _.set(headers, "list", getFieldListData(pages, model, field));
      }

      return headers;
    }
  );
};

export const setListDataToModels = (data, headers, model = null) => {
  _.each(headers, header => {
    if (_.get(header, "is_foreign")) {
      data = _.map(data, m => {
        switch (header.relation_type) {
          case RELATION_TYPES.MANY_TO_MANY_COMMA:
          case RELATION_TYPES.MANY_TO_MANY:
            const related = _.filter(header.list, list =>
              _.includes(m[header.value], list.id || list.ID)
            );
            return {
              ...m,
              [header.value]: _.reduce(
                related,
                (prev, next) => (prev ? `${prev}, ${next.name}` : next.name),
                ""
              )
            };
          case RELATION_TYPES.ONE_TO_MANY:
          case RELATION_TYPES.ONE_TO_MANY_IMAGES:
            return {
              ...m,
              [header.value]: _.join(
                _.map(
                  m[header.value],
                  column => column[header.foreign_table_column]
                ),
                ","
              )
            };
          default:
            return {
              ...m,
              [header.value]: _.get(
                _.find(header.list, { id: _.get(m, header.value) }),
                "name"
              )
            };
        }
      });
    }
  });

  if (model === "domain") {
    data.unshift({
      default_language: "nl",
      not_modifiable: true,
      name: window.location.hostname
    });
  }

  return data;
};

export const labelsToIds = (collection, labels, col) => {
  return _.map(labels, label => {
    return _.chain(collection)
      .find(o => _.get(o, col) === label)
      .get("id")
      .value();
  });
};

export const objToUrlParam = payload => {
  return Object.entries(payload)
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&");
};
