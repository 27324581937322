// Vuetify Documentation https://vuetifyjs.com

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import Vue from "vue";
import pdf from 'vue-pdf';
import Vuetify from "vuetify/lib";
import Ripple from 'vuetify/lib/directives/ripple';
import { themePreset } from "../../src/themeConfig";

const vuetify = new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl
});

Vue.use(Vuetify, {
  directives: { Ripple }
});
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' });
Vue.use(pdf);

export default vuetify;
